<template>
    <v-row class="text-h5">
            {{value.code.toUpperCase()}}: {{value.amount}}
    </v-row>
</template>

<script>

export default {
    name: "TaxView",
    props:{
        value:{
            type: Object,
            default: {}
        }
    },
};
</script>

<style>
</style>