<template>
    <v-container class="pt-3 pb-3" style="height: 90vh">
        <v-row >
            <div class="total">
                Итого: {{ value.total }}₽
            </div>
        </v-row>
        <v-row>
            <v-divider />
        </v-row>
        <v-row class="mt-10">
            <v-col cols="12" md="4">
                <tax-view v-for="(tax) of murcupTaxes" :key="tax.code" :value="tax" class="pb-5" />
            </v-col>
            <v-col cols="12" md="8">
                <tax-view v-for="(tax) of value.taxes" :key="tax.code" :value="tax" class="pb-5" />
            </v-col>
        </v-row>
        <v-col cols="12" md="6">
        </v-col>
    </v-container>
</template>

<script>
import TaxView from "@/components/ExternalScreenPage/View/TaxView.vue";

export default {
    name: "PriceView",
    components: { TaxView },
    props: {
        value: {
            type: Object,
            default: {}
        }
    },
    methods: {
        getMarcupTaxes(val) {
            if (!val) return [{ code: 'НА', amount: 0 }, { code: 'КО', amount: 0 }, { code: 'AP', amount: 0 },]
            if (val <= 1500) return [{ code: 'НА', amount: val }, { code: 'КО', amount: 0 }, { code: 'AP', amount: 0 },]
            var toDevide = val - 1500;
            var first = toDevide > 10 ? Math.floor((toDevide * 0.6) / 10) * 10 : toDevide;
            var second = toDevide - first
            return [{ code: 'НА', amount: 1500 }, { code: 'КО', amount: first }, { code: 'AP', amount: second },]
        }
    },
    computed: {
        murcupTaxes() {
            if (!this.value || !this.value.marcup) return [];
            return this.getMarcupTaxes(this.value.marcup)
        },
    }
};
</script>

<style>
.total {
    font-size: 115px;
}
</style>